import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StockPricesVertical = () => {
  const [prices, setPrices] = useState([]);
  const [marketData, setMarketData] = useState(null);
  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  const [loading, setLoading] = useState(true);

  const coinIds = [
    "tether", // USDT
    "tron", // TRX
    "bitcoin", // BTC
    "ethereum", // ETH
    "solana", // SOL
    "binancecoin", // BNB
    "ripple", // XRP
    "dogecoin", // DOGE
    "cardano", // ADA
    "usd-coin", // USDC
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([fetchCryptoPricesData(), fetchMarketData(), fetchTopGainersAndLosers()]);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const fetchCryptoPricesData = async () => {
    try {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinIds.join(",")}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch crypto prices');
      }
      const data = await response.json();
      setPrices(data);
    } catch (error) {
      console.error('Error fetching crypto prices:', error.message);
    }
  };

  const fetchMarketData = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/global');
      if (!response.ok) {
        throw new Error('Failed to fetch market data');
      }
      const data = await response.json();
      setMarketData(data.data);
    } catch (error) {
      console.error('Error fetching market data:', error.message);
    }
  };

  const fetchTopGainersAndLosers = async () => {
    try {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=price_change_percentage_24h_desc&per_page=10&page=1'
      );
      if (!response.ok) {
        throw new Error('Failed to fetch gainers and losers');
      }
      const data = await response.json();
      setGainers(data.slice(0, 5));
      setLosers(data.slice(-5).reverse());
    } catch (error) {
      console.error('Error fetching gainers and losers:', error.message);
    }
  };

  return (
    <Container>
      {loading && <Loading>Loading data...</Loading>}
      <Section>
        <Title>Cryptocurrency Prices</Title>
        <PricesTable>
          <thead>
            <tr>
              <TableHeader>Icon</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Price (USD)</TableHeader>
            </tr>
          </thead>
          <tbody>
            {prices.map((crypto) => (
              <TableRow key={crypto.id}>
                <TableData>
                  <CryptoIcon src={crypto.image} alt={crypto.name} />
                </TableData>
                <TableData>{crypto.name}</TableData>
                <TableData>${crypto.current_price.toLocaleString()}</TableData>
              </TableRow>
            ))}
          </tbody>
        </PricesTable>
      </Section>
      {marketData && (
        <MarketData>
          <Title>Global Market Data</Title>
          <DataText>Total Market Cap: ${marketData.total_market_cap.usd.toLocaleString()}</DataText>
          <DataText>Total 24h Volume: ${marketData.total_volume.usd.toLocaleString()}</DataText>
          <DataText>BTC Dominance: {marketData.market_cap_percentage.btc.toFixed(2)}%</DataText>
        </MarketData>
      )}
      <GainersLosers>
        <Gainers>
          <GainersTitle>Top Gainers (24h)</GainersTitle>
          <List>
            {gainers.map((gainer) => (
              <ListItem key={gainer.id}>
                <span>{gainer.name}:</span> +{gainer.price_change_percentage_24h.toFixed(2)}%
              </ListItem>
            ))}
          </List>
        </Gainers>
        <Losers>
          <LosersTitle>Top Losers (24h)</LosersTitle>
          <List>
            {losers.map((loser) => (
              <ListItem key={loser.id}>
                <span>{loser.name}:</span> {loser.price_change_percentage_24h.toFixed(2)}%
              </ListItem>
            ))}
          </List>
        </Losers>
      </GainersLosers>
    </Container>
  );
};

export default StockPricesVertical;

// Styled components (same as before)
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  background: #f9fafc;
  color: #333;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: #2b2d42;
  margin-bottom: 20px;
  text-align: center;
`;

const PricesTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.th`
  padding: 15px;
  background: #e8eaf6;
  font-size: 18px;
  color: #333;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f4f4f4;
  }
`;

const TableData = styled.td`
  padding: 15px;
  text-align: center;
  font-size: 16px;
`;

const CryptoIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const MarketData = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const DataText = styled.p`
  font-size: 18px;
  margin: 10px 0;
`;

const GainersLosers = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Gainers = styled.div`
  width: 48%;
`;

const Losers = styled.div`
  width: 48%;
`;

const GainersTitle = styled.h2`
  color: green;
`;

const LosersTitle = styled.h2`
  color: red;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 16px;
  margin: 8px 0;
`;

const Loading = styled.p`
  color: #555;
  font-size: 18px;
  text-align: center;
`;
