import React from 'react';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  { text: "This platform has completely transformed my investment strategy. The ROI is unmatched, and the security is top-notch.", name: "Jane D.", role: "Investor" },
  { text: "The customer support team is exceptional. They responded quickly and resolved all of my issues within minutes. I’m a loyal customer now!", name: "John R.", role: "VIP Member" },
  { text: "I was able to achieve a 30% growth in just three months using this platform. The tools and insights provided are second to none!", name: "Mark H.", role: "Investor" },
  { text: "Great platform! I’ve earned consistent returns and feel confident about my investments.", name: "Sarah P.", role: "Investor" },
  { text: "Seamless experience from start to finish. I can monitor and manage my investments effortlessly.", name: "David W.", role: "Investor" },
  { text: "This platform makes digital investing easy. The returns are great, and the customer service is fast!", name: "Laura T.", role: "Investor" },
  { text: "The VIP program unlocked many perks that helped me take my investments to the next level.", name: "Michael S.", role: "VIP Member" },
  { text: "Incredibly user-friendly. I was able to start investing without any hassle. Highly recommended!", name: "Emma L.", role: "Investor" },
  { text: "The transparency and reliability of this platform are impressive. I feel safe investing here.", name: "Chris J.", role: "Investor" },
  { text: "A fantastic platform that truly understands the needs of modern investors. I’m so happy I joined.", name: "Oliver N.", role: "VIP Member" },
  // Add more testimonials as needed...
];

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <TestimonialsSection>
      <h2>What Our Investors Are Saying</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index}>
            <p className="testimonial-text">"{testimonial.text}"</p>
            <span className="testimonial-name">- {testimonial.name}</span>
            <span className="testimonial-role">{testimonial.role}</span>
            <Rating>
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
            </Rating>
          </TestimonialCard>
        ))}
      </Slider>
    </TestimonialsSection>
  );
};

export default Testimonials;

// Styled Components

const TestimonialsSection = styled.section`
  text-align: center;
  margin: 80px 0;
  padding: 50px;
  background-color: #f4f7fc;
  position: relative;
  overflow: hidden;
  font-family: 'Inter', sans-serif;

  h2 {
    font-size: 42px;
    font-weight: 600;
    color: #0d47a1; /* Dark blue (previously the header color) */
    margin-bottom: 40px;
    letter-spacing: 1px;
    font-family: 'Lora', serif; /* Elegant serif font */
  }
`;

const TestimonialCard = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 20px;
  transition: transform 0.3s ease;
  width: 90%;
  max-width: 350px;
  box-sizing: border-box;
  margin-bottom: 30px;
  display: inline-block;
  font-family: 'Inter', sans-serif;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }

  .testimonial-text {
    font-size: 20px;
    color: #333;
    font-style: italic;
    line-height: 1.8;
    margin-bottom: 20px;
    font-family: 'Lora', serif;
  }

  .testimonial-name {
    font-size: 18px;
    color: #0d47a1;
    font-weight: 600;
  }

  .testimonial-role {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
  }
`;

const Rating = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: #FFD700; /* Golden stars for the rating */

  .star-icon {
    font-size: 24px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

// Slick carousel styles (you can apply these to your global styles or styled components if needed)
const SlickPrevNext = styled.div`
  display: none;
`;

const SlickDots = styled.div`
  display: none;
`;

const SlickTrack = styled.div`
  display: flex;
  justify-content: space-between;
`;

// Mobile responsiveness
const MediaQueries = styled.div`
  @media (max-width: 768px) {
    ${TestimonialsSection} {
      h2 {
        font-size: 36px;
      }

      .testimonial-text {
        font-size: 18px;
      }

      .testimonial-name {
        font-size: 16px;
      }

      .testimonial-role {
        font-size: 12px;
      }

      ${TestimonialCard} {
        margin: 15px 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
