import React from 'react';
import styled from 'styled-components';

const HomeNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  background-color: #f4f7fb; /* Light blue-gray for branding */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;  /* Prevent overflow */
`;

const Heading = styled.h2`
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #0d47a1; /* Branding blue */
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Branding font */
  word-wrap: break-word;  /* Prevent overflow in long words */
`;

const NavGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
  overflow: hidden;  /* Prevent overflow */
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);  /* Adjust for medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);  /* Adjust for small screens */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;  /* Single column on extra small screens */
  }
`;

const NavItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333; /* Neutral text color */
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #e3f2fd; /* Light branding blue for hover */
  }

  &:active {
    background-color: #bbdefb; /* Slightly darker blue for active */
  }
`;

const NavIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
`;

const ItemText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #555; /* Neutral text color */
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Branding font */
  transition: color 0.3s ease-in-out;

  ${NavItem}:hover & {
    color: #0d47a1; /* Branding blue for hover */
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const HomeNavigation = () => {
  const sections = [
    { name: 'Wallet', icon: '/icons/wallet.png', link: '/wallet' },
    { name: 'Recharge', icon: '/icons/recharge.png', link: '/recharge' },
    { name: 'Withdraw', icon: '/icons/withdraw.png', link: '/withdraw' },
    { name: 'Transfer', icon: '/icons/transfer.png', link: '/transfer' },
    { name: 'Help Center', icon: '/icons/help.png', link: '/help' },
    { name: 'Investment', icon: '/icons/investment.png', link: '/investment' },
    { name: 'VIP', icon: '/icons/vip.png', link: '/vip' },
    { name: 'Contact Customer Service', icon: '/icons/contact.png', link: '/contact' }
  ];

  return (
    <HomeNavigationContainer>
      <Heading>Quick Access</Heading>
      <NavGrid>
        {sections.map((section, index) => (
          <NavItem key={index} href={section.link}>
            <IconContainer>
              <NavIcon src={section.icon} alt={section.name} />
            </IconContainer>
            <ItemText>{section.name}</ItemText>
          </NavItem>
        ))}
      </NavGrid>
    </HomeNavigationContainer>
  );
};

export default HomeNavigation;
