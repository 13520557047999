import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyPage = () => {
  return (
    <PrivacyContainer>
      <PageHeader>Privacy Policy</PageHeader>
      <Introduction>
        Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our services.
      </Introduction>
      <Section>
        <SectionTitle>1. Information We Collect</SectionTitle>
        <SectionContent>
          We may collect the following types of information:
          <ul>
            <li><b>Personal Information:</b> Name, email address, phone number, and payment details.</li>
            <li><b>Usage Data:</b> Information about how you interact with our platform, such as pages viewed and time spent.</li>
            <li><b>Device Information:</b> IP address, browser type, and operating system.</li>
          </ul>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>2. How We Use Your Information</SectionTitle>
        <SectionContent>
          The information we collect is used to:
          <ul>
            <li>Provide and improve our services.</li>
            <li>Process transactions and send confirmations.</li>
            <li>Communicate with you regarding updates or promotional offers.</li>
            <li>Ensure security and prevent fraudulent activities.</li>
          </ul>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>3. Information Sharing</SectionTitle>
        <SectionContent>
          We do not sell or rent your personal information. However, we may share your data with trusted third parties, such as payment processors, only to fulfill the purposes described in this policy.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>4. Data Security</SectionTitle>
        <SectionContent>
          We implement robust security measures to protect your data from unauthorized access, alteration, or destruction. However, no method of transmission over the internet is 100% secure.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>5. Your Rights</SectionTitle>
        <SectionContent>
          You have the right to:
          <ul>
            <li>Access the personal information we hold about you.</li>
            <li>Request corrections to your data if it is inaccurate.</li>
            <li>Request deletion of your personal data, subject to legal obligations.</li>
          </ul>
          To exercise these rights, contact us at <ContactLink href="mailto:privacy@example.com">privacy@example.com</ContactLink>.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>6. Cookies</SectionTitle>
        <SectionContent>
          Our platform uses cookies to enhance user experience and analyze site performance. You can manage your cookie preferences through your browser settings.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>7. Changes to This Policy</SectionTitle>
        <SectionContent>
          We may update this Privacy Policy from time to time. Any changes will be communicated via our platform, and your continued use constitutes acceptance of the updated terms.
        </SectionContent>
      </Section>
      <Conclusion>
        If you have any questions or concerns about this Privacy Policy, please contact us.
      </Conclusion>
    </PrivacyContainer>
  );
};

export default PrivacyPolicyPage;

// Styled Components

const PrivacyContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px 40px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #444;
`;

const PageHeader = styled.h1`
  font-size: 2.4rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #27ae60;
  padding-bottom: 10px;
`;

const Introduction = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.6rem;
  color: #27ae60;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #555;

  ul {
    margin: 10px 0;
    padding-left: 20px;

    li {
      margin-bottom: 8px;
    }
  }
`;

const Conclusion = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #444;
  margin-top: 30px;
  text-align: center;
`;

const ContactLink = styled.a`
  color: #27ae60;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
