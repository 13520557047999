import React from 'react';
import styled from 'styled-components';

const CTA = () => {
  return (
    <CTASection>
      <h2>Maximize Your Potential</h2>
      <p>Start investing with confidence and watch your wealth grow with ease.</p>
      <CtaButton href="/investment">Take Action</CtaButton>
    </CTASection>
  );
};

export default CTA;

// Styled Components

const CTASection = styled.section`
  text-align: center;
  margin: 80px 0;
  padding: 60px;
  background-color: #38BDF8; /* Teal Blue for primary accent */
  color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  font-family: 'Inter', sans-serif;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(45deg, rgba(56, 189, 248, 0.15), rgba(56, 189, 248, 0.05)); /* Lighter gradient of teal */
    opacity: 0.7;
    z-index: -1;
  }
`;

const CtaButton = styled.a`
  background-color: #F59E0B; /* Golden Yellow for the CTA button */
  color: #1E3A8A; /* Dark Blue for contrast text */
  padding: 14px 30px;
  font-size: 18px;
  text-decoration: none;
  border-radius: 25px;  /* More rounded corners for a sleek look */
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;

  &:hover {
    background-color: #ffffff;
    color: #0056b3; /* Slightly darker blue on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;

CTASection.h2 = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #fff;
`;

CTASection.p = styled.p`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.85); /* Slightly lighter text for contrast */
  margin-bottom: 30px;
  font-family: 'Lora', serif;  /* Serif font for text to add elegance */
`;
