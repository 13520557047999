import React from 'react';
import styled from 'styled-components';

const AboutUs = () => {
  return (
    <AboutUsSection>
      <AboutUsContainer>
        <AboutUsText>
          <h2>Empowering Your Financial Future</h2>
          <p>
            We are a trusted platform that provides secure investment opportunities in the digital asset world. Our goal is to help you manage and grow your wealth through innovative solutions and seamless transactions. Join a community of forward-thinking investors and take control of your financial journey with us.
          </p>
          <p>
            Whether you're looking to invest in high-return packages, manage your funds easily, or unlock exclusive VIP benefits, we're here to make your financial journey effortless and rewarding.
          </p>
        </AboutUsText>
        <AboutUsImage>
          <img src="/images/promo1.jpeg" alt="Financial Growth" />
        </AboutUsImage>
      </AboutUsContainer>
    </AboutUsSection>
  );
};

export default AboutUs;

// Styled Components

const AboutUsSection = styled.section`
  padding: 80px 20px;
  background-color: #f4f7fc;  /* Soft background color to match the brand */
  color: #333;
  font-family: 'Inter', sans-serif;
`;

const AboutUsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutUsText = styled.div`
  flex: 1;
  padding-right: 40px;

  h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #0d47a1;  /* Dark blue to match the branding */
  }

  p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #555;
    font-family: 'Lora', serif;  /* Elegant serif font for paragraphs */
  }

  @media (max-width: 768px) {
    text-align: center;
    padding-right: 0;
  }
`;

const AboutUsImage = styled.div`
  flex: 1;
  max-width: 500px;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    max-width: 100%;
    height: 250px;
  }
`;
