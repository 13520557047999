import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FaCrown,
  FaStar,
  FaGem,
  FaTrophy,
  FaMedal,
  FaArrowRight,
  FaArrowLeft,
  FaExchangeAlt,
  FaWallet,
  FaPiggyBank,
  FaMoneyBillWave,
} from 'react-icons/fa';

// Styled Components
const PageContainer = styled.div`
  padding: 40px;
  background: linear-gradient(135deg, #e0eafc, #cfdef3);
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2.5em;
  color: #2b2d42;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: uppercase;
`;

const CurrentDetailsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto 40px;
  text-align: left;
`;

const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailRow = styled.div`
  font-size: 1.2em;
  color: #555;
  margin: 10px 0;
  display: flex;
  align-items: center;

  strong {
    font-weight: 600;
    color: #333;
  }

  span {
    color: #0077B6;
    font-weight: 500;
  }

  svg {
    margin-right: 10px;
    color: #0077b6;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 1.2em;
  margin: 20px 0;
`;

const LevelsSection = styled.div`
  margin-top: 40px;
`;

const LevelDisplay = styled.div`
  background-color: ${(props) => props.bgColor};
  color: #fff;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const LevelTitle = styled.h4`
  font-size: 2em;
  margin-bottom: 20px;
`;

const LevelIcon = styled.div`
  font-size: 3em;
  margin-bottom: 20px;
`;

const LevelDetails = styled.ul`
  font-size: 1.2em;
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;

  li {
    margin: 10px 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      color: #fff;
    }
  }
`;

const Button = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background-color: #ff6f61;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 25px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e55b50;
  }
`;

const NavigationButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const NavButton = styled.button`
  background: ${(props) => (props.disabled ? "#ccc" : "#0077B6")};
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => (props.disabled ? "#ccc" : "#005f87")};
  }
`;

// Main Component
const VIP = () => {
  const [vipDetails, setVipDetails] = useState(null);
  const [error, setError] = useState(null);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);

  const vipLevels = [
    {
      level_name: "Starter",
      transfer_limit_daily: 200,
      transfer_limit_monthly: 2500,
      withdrawal_limit_daily: 100,
      withdrawal_limit_monthly: 1500,
      icon: <FaStar />,
    },
    {
      level_name: "Basic",
      transfer_limit_daily: 900,
      transfer_limit_monthly: 9500,
      withdrawal_limit_daily: 500,
      withdrawal_limit_monthly: 5000,
      icon: <FaGem />,
    },
    {
      level_name: "Pro",
      transfer_limit_daily: 1500,
      transfer_limit_monthly: 18000,
      withdrawal_limit_daily: 800,
      withdrawal_limit_monthly: 9000,
      icon: <FaTrophy />,
    },
    {
      level_name: "Premium",
      transfer_limit_daily: 10000,
      transfer_limit_monthly: 25000,
      withdrawal_limit_daily: 5000,
      withdrawal_limit_monthly: 12000,
      icon: <FaMedal />,
    },
    {
      level_name: "Platinum",
      transfer_limit_daily: 30000,
      transfer_limit_monthly: 8000000,
      withdrawal_limit_daily: 15000,
      withdrawal_limit_monthly: 40000,
      icon: <FaCrown />,
    },
  ];

  const fetchVIPDetails = async () => {
    try {
      const response = await fetch("https://limitincrease.xyz/api/vip-level", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch VIP details.");
      }

      const data = await response.json();
      setVipDetails(data);
    } catch (error) {
      console.error("Error fetching VIP details:", error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchVIPDetails();
  }, []);

  const nextLevel = () => {
    setCurrentLevelIndex((prev) => Math.min(prev + 1, vipLevels.length - 1));
  };

  const prevLevel = () => {
    setCurrentLevelIndex((prev) => Math.max(prev - 1, 0));
  };

  const getVIPColor = (index) => {
    const colors = ["#0077B6", "#00B4D8", "#48C9B0", "#2C3E50", "#FF5733"];
    return colors[index];
  };

  return (
    <PageContainer>
      <Heading>Your VIP Level Details</Heading>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {vipDetails && (
        <CurrentDetailsContainer>
          <DetailContent>
            <DetailRow>
              <FaCrown />
              <strong>Level Name:</strong> <span>{vipDetails.level_name}</span>
            </DetailRow>
            <DetailRow>
              <FaExchangeAlt />
              <strong>Daily Transfer Limit:</strong>{" "}
              <span>{vipDetails.transfer_limit_daily} USDT</span>
            </DetailRow>
            <DetailRow>
              <FaPiggyBank />
              <strong>Monthly Transfer Limit:</strong>{" "}
              <span>{vipDetails.transfer_limit_monthly} USDT</span>
            </DetailRow>
            <DetailRow>
              <FaWallet />
              <strong>Daily Withdrawal Limit:</strong>{" "}
              <span>{vipDetails.withdrawal_limit_daily} USDT</span>
            </DetailRow>
            <DetailRow>
              <FaMoneyBillWave />
              <strong>Monthly Withdrawal Limit:</strong>{" "}
              <span>{vipDetails.withdrawal_limit_monthly} USDT</span>
            </DetailRow>
          </DetailContent>
        </CurrentDetailsContainer>
      )}

      {/* VIP Levels Section */}
      <LevelsSection>
        <LevelDisplay bgColor={getVIPColor(currentLevelIndex)}>
          <LevelIcon>{vipLevels[currentLevelIndex].icon}</LevelIcon>
          <LevelTitle>{vipLevels[currentLevelIndex].level_name}</LevelTitle>
          <LevelDetails>
            <li>
              <FaExchangeAlt /> Daily Transfer Limit:{" "}
              {vipLevels[currentLevelIndex].transfer_limit_daily} USDT
            </li>
            <li>
              <FaPiggyBank /> Monthly Transfer Limit:{" "}
              {vipLevels[currentLevelIndex].transfer_limit_monthly} USDT
            </li>
            <li>
              <FaWallet /> Daily Withdrawal Limit:{" "}
              {vipLevels[currentLevelIndex].withdrawal_limit_daily} USDT
            </li>
            <li>
              <FaMoneyBillWave /> Monthly Withdrawal Limit:{" "}
              {vipLevels[currentLevelIndex].withdrawal_limit_monthly} USDT
            </li>
          </LevelDetails>
          <Button href="/investment">Upgrade to {vipLevels[currentLevelIndex].level_name}</Button>
        </LevelDisplay>
        <NavigationButtons>
          <NavButton onClick={prevLevel} disabled={currentLevelIndex === 0}>
            <FaArrowLeft />
          </NavButton>
          <NavButton
            onClick={nextLevel}
            disabled={currentLevelIndex === vipLevels.length - 1}
          >
            <FaArrowRight />
          </NavButton>
        </NavigationButtons>
      </LevelsSection>
    </PageContainer>
  );
};

export default VIP;
