import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import CarouselComponent from './components/CarouselComponent';
import ServiceOverview from './components/ServiceOverview';
import StockPricesVertical from './components/StockPricesVertical';
import HomeNavigation from './components/HomeNavigation';
import FooterMenu from './components/FooterMenu';
import Wallet from './components/wallet'; // Import the Wallet component
import Login from './components/LoginForm';  // Import the Login component
import Register from './components/RegisterForm';  // Import the Register component
import ResetPassword from './components/ResetPassword';  // Import the ResetPassword component
import Investment from './components/Investment'; // Import the Investment component
import './App.css';
import ProfilePage from './components/ProfilePage';
import RechargePage from './components/RechargePage'; 
import WithdrawForm from './components/WithdrawForm';
import TransferForm from './components/TransferForm';
import TransactionHistory from './components/TransactionHistory';
import HelpCenter from './components/HelpCenter';
import ContactSupport from './components/ContactSupport';
import VIP from './components/VIP';
import Dashboard from './components/Dashboard';
import Refferal from './components/ReferralRewards'
import AboutUs from './components/AboutUs';
import Benefits from './components/Benefits';
import Testimonials from './components/Testimonials';
import CTA from './components/CTA';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordChangeForm from './components/PasswordChangeForm';
import Logout from './components/Logout';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import SliderPage from './components/SliderPage';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const isAuthPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/reset-password' || location.pathname === '/request-password-reset';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token && !isAuthPage) {
      navigate('/login');
    }
  }, [location, navigate, isAuthPage]);

  return (
    <>
      {!isAuthPage && <Header />}

      <Routes>
        <Route path="/" element={
          <>
            <CarouselComponent />
            <HomeNavigation />
            <ServiceOverview />
            <AboutUs/>
            <Benefits />
            <Testimonials />
            <SliderPage />
      <CTA />
          </>
        } />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/investment" element={<Investment />} /> {/* Add investment route */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/recharge" element={<RechargePage />} />
        <Route path="/withdraw" element={<WithdrawForm />} />
        <Route path="/vip" element={<VIP />} />
        <Route path="/transfer" element={<TransferForm />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/transactions" element={<TransactionHistory />} />
        <Route path="/help" element={<HelpCenter />} />
        <Route path="/contact" element={<ContactSupport />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/referral" element={<Refferal />} />
        <Route path="/password-change" element={<PasswordChangeForm />} />
        <Route path="/request-password-reset" element={<PasswordResetRequest />} />
        <Route path="/stock-vertical" element={<StockPricesVertical />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        {/* Other routes for additional pages */}
      </Routes>

      {!isAuthPage && <FooterMenu />}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
