import React from 'react';
import styled from 'styled-components';

const TermsPage = () => {
  return (
    <TermsContainer>
      <PageHeader>Terms and Conditions</PageHeader>
      <Introduction>
        Welcome to our platform. By accessing or using our services, you agree to abide by these terms and conditions. Please read them carefully before proceeding.
      </Introduction>
      <Section>
        <SectionTitle>1. Acceptance of Terms</SectionTitle>
        <SectionContent>
          By using our platform, you confirm that you accept these terms. If you do not agree, please refrain from using our services. These terms may be updated periodically, and your continued use signifies acceptance of any changes.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>2. User Responsibilities</SectionTitle>
        <SectionContent>
          As a user, you agree to provide accurate information and maintain the confidentiality of your account details. Unauthorized use of your account is strictly prohibited, and you are responsible for all activities under your account.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>3. Prohibited Activities</SectionTitle>
        <SectionContent>
          Users must not engage in any unlawful activities, including but not limited to hacking, fraud, or distribution of malicious software. Violations may result in account termination and legal actions.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>4. Service Limitations</SectionTitle>
        <SectionContent>
          While we strive to provide uninterrupted services, we cannot guarantee uptime or prevent all potential issues. We are not liable for any losses arising from service disruptions.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>5. Privacy Policy</SectionTitle>
        <SectionContent>
          Your privacy is important to us. Please review our Privacy Policy to understand how your information is collected, used, and safeguarded.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>6. Termination</SectionTitle>
        <SectionContent>
          We reserve the right to terminate your access to our platform for any violation of these terms or for any reason deemed necessary, with or without notice.
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>7. Governing Law</SectionTitle>
        <SectionContent>
          These terms are governed by and construed in accordance with the laws of the applicable jurisdiction. Any disputes shall be resolved in the courts of that jurisdiction.
        </SectionContent>
      </Section>
      <Conclusion>
        Thank you for using our services. 
      </Conclusion>
    </TermsContainer>
  );
};

export default TermsPage;

// Styled Components

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px 40px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #444;
`;

const PageHeader = styled.h1`
  font-size: 2.4rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const Introduction = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.6rem;
  color: #3498db;
  margin-bottom: 10px;
`;

const SectionContent = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
`;

const Conclusion = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #444;
  margin-top: 30px;
  text-align: center;
`;

const ContactLink = styled.a`
  color: #3498db;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
