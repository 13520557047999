import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f093fb, #f5576c); /* Subtle gradient based on brand colors */
  margin: 0;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0047ba; /* Primary brand color */
`;

const Message = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #0047ba; /* Focus color changed to brand */
    box-shadow: 0 0 8px rgba(0, 71, 186, 0.3); /* Soft brand color glow */
    background: #ffffff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background: linear-gradient(135deg, #0047ba, #00d4ff); /* Gradient using brand colors */
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    background: linear-gradient(135deg, #00d4ff, #0047ba); /* Hover color reversed */
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c; /* Error color changed to a consistent brand red */
  font-size: 0.9rem;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #28a745; /* Success color kept as is, for positive feedback */
  font-size: 0.9rem;
  margin-top: 10px;
`;

const LoginLink = styled.div`
  margin-top: 20px;

  a {
    font-size: 0.9rem;
    color: #0047ba; /* Brand color for the link */
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await axios.post('https://limitincrease.xyz/api/request-password-reset', { email });
      setMessage(response.data.message); // Ensure that this is the correct field
    } catch (err) {
      // Log the entire error for debugging
      console.error('Error response:', err.response);

      if (err.response && err.response.data) {
        // Access the specific error message based on your API's error response structure
        const errorMessage = err.response.data.msg || err.response.data.detail || 'Something went wrong.';
        setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage)); // Ensure it's a string
      } else {
        setError('An unexpected error occurred.'); // Generic error message
      }
    }
  };

  return (
    <Container>
      <Card>
        <Heading>Request Password Reset</Heading>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </FormGroup>
          <Button type="submit">Send Password Reset Link</Button>
        </form>
        {message && <SuccessMessage>{message}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <LoginLink>
          <a href="/login">Back to Login</a>
        </LoginLink>
      </Card>
    </Container>
  );
};

export default PasswordResetRequest;
