import React, { useEffect, useState } from 'react';
import Transactions from './TransactionHistory'; // Enhanced Transactions Component
import styled from 'styled-components'; // Import styled-components
import { FaUser, FaEnvelope, FaPhone, FaWallet, FaBalanceScale } from 'react-icons/fa'; // Icons for user details

const Dashboard = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);

  // Decode JWT to check for expiry
  const parseJWT = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const jsonPayload = JSON.parse(atob(base64));
      return jsonPayload;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      console.log('Token:', token); // Check if token is present

      if (!token) {
        setError('User is not authenticated. Token is missing.');
        return;
      }

      // Check if the token is expired
      const decodedToken = parseJWT(token);
      if (decodedToken && decodedToken.exp < Date.now() / 1000) {
        setError('Token has expired. Please log in again.');
        return;
      }

      try {
        const response = await fetch('https://limitincrease.xyz/api/dashboard', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details.');
        }

        const data = await response.json();
        setUserDetails(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUserDetails();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? new Date(dateString.split('.')[0] + 'Z').toLocaleString() // Attempt ISO format parsing
      : date.toLocaleString();
  };

  return (
    <DashboardContainer>
      <DashboardTitle>User Dashboard</DashboardTitle>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {userDetails ? (
        <DashboardContent>
          <UserInfo>
            <SectionTitle>User Information</SectionTitle>
            <InfoRow>
              <InfoLabel><FaUser /> Username:</InfoLabel>
              <InfoValue>{userDetails.username}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel><FaEnvelope /> Email:</InfoLabel>
              <InfoValue>{userDetails.email}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel><FaPhone /> Phone:</InfoLabel>
              <InfoValue>{userDetails.phone}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel><FaBalanceScale /> VIP Level:</InfoLabel>
              <InfoValue>{userDetails.vip_level}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel><FaWallet /> Wallet ID:</InfoLabel>
              <InfoValue>{userDetails.wallet_id}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel><FaBalanceScale /> Balance:</InfoLabel>
              <InfoValue>{userDetails.balance.toFixed(2)} USDT</InfoValue>
            </InfoRow>
          </UserInfo>

          <TransactionsSection>
            <SectionTitle>Transaction History</SectionTitle>
            <Transactions />
          </TransactionsSection>
        </DashboardContent>
      ) : (
        <LoadingMessage>Loading user details...</LoadingMessage>
      )}
    </DashboardContainer>
  );
};

export default Dashboard;

// Styled Components

const DashboardContainer = styled.div`
  max-width: 1400px;
  margin: 20px auto;
  padding: 20px;
  background: #f5f7fa;
  border-radius: 16px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  overflow: hidden;  /* Prevent overflow */
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const DashboardTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  color: #0d47a1;
  text-align: center;
  margin-bottom: 20px;
  word-wrap: break-word; /* Prevents overflow in long words */
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ErrorMessage = styled.p`
  color: #e53935;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const DashboardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  overflow: hidden;  /* Prevent overflow */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const UserInfo = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;  /* Prevent overflow */
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
  }
`;

const InfoLabel = styled.span`
  font-weight: 600;
  color: #5c6bc0;
  display: flex;
  align-items: center;
  word-wrap: break-word;  /* Prevent overflow */
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const InfoValue = styled.span`
  font-weight: 500;
  color: #37474f;
  word-wrap: break-word;  /* Prevent overflow */
`;

const TransactionsSection = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;  /* Prevent overflow */
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  color: #0d47a1;
  margin-bottom: 20px;
  word-wrap: break-word;  /* Prevent overflow */
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #78909c;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

