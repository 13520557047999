import React from 'react';
import styled from 'styled-components';
import { FaShieldAlt, FaFastForward, FaHeadset, FaStar, FaHandsHelping, FaTrophy } from 'react-icons/fa';

const Benefits = () => {
  return (
    <BenefitsSection>
      <h2>Why Invest with Us?</h2>
      <BenefitsSubheading>
        Unlock the potential for growth with our secure, fast, and rewarding platform. Here's why thousands of investors choose us:
      </BenefitsSubheading>
      <BenefitsGrid>
        <BenefitItem>
          <FaShieldAlt className="benefit-icon" />
          <h3>Top-Level Security</h3>
          <p>We use advanced encryption to keep your assets safe. Your peace of mind is our priority.</p>
        </BenefitItem>
        <BenefitItem>
          <FaFastForward className="benefit-icon" />
          <h3>Instant Transactions</h3>
          <p>Send and receive your funds quickly with minimal fees—it's fast and efficient.</p>
        </BenefitItem>
        <BenefitItem>
          <FaHeadset className="benefit-icon" />
          <h3>24/7 Customer Support</h3>
          <p>Need help? Our team is always ready to assist you, no matter the time or day.</p>
        </BenefitItem>
        <BenefitItem>
          <FaStar className="benefit-icon" />
          <h3>Exclusive VIP Perks</h3>
          <p>Elevate your experience with exclusive offers, higher limits, and personalized support.</p>
        </BenefitItem>
        <BenefitItem>
          <FaHandsHelping className="benefit-icon" />
          <h3>Community Support</h3>
          <p>Join a thriving community of like-minded investors and share knowledge and insights.</p>
        </BenefitItem>
        <BenefitItem>
          <FaTrophy className="benefit-icon" />
          <h3>Rewards for Growth</h3>
          <p>Earn rewards as you grow your portfolio with us—reinvest and watch your assets rise.</p>
        </BenefitItem>
      </BenefitsGrid>
      <CtaContainer>
        <p>Ready to start your investment journey? <strong>Join us now</strong> and experience the future of finance!</p>
      </CtaContainer>
    </BenefitsSection>
  );
};

export default Benefits;

// Styled Components

const BenefitsSection = styled.section`
  padding: 60px 20px;
  background-color: #f4f7fc;  /* Soft background color */
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

const BenefitsSubheading = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.6;
  font-family: 'Lora', serif;  /* Elegant font for subheading */
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));  /* Responsive grid */
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BenefitItem = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Inter', sans-serif;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }

  .benefit-icon {
    font-size: 40px;
    color: #007bff; /* Primary branding color for icons */
    margin-bottom: 15px;
  }

  h3 {
    font-size: 24px;
    color: #0d47a1;  /* Dark blue for headings */
    margin-bottom: 15px;
    font-weight: 600;
  }

  p {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
  }
`;

const CtaContainer = styled.div`
  margin-top: 50px;

  p {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;  /* Brand color for CTA text */
    margin-top: 20px;
  }

  strong {
    color: #28a745;  /* Green for call to action */
    cursor: pointer;
    transition: color 0.3s ease;
  }

  strong:hover {
    color: #218838;  /* Darker green on hover */
  }
`;
