import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = () => {
      // Remove the JWT token from localStorage
      localStorage.removeItem('token');

      // Redirect the user to the login page
      navigate('/login', { replace: true });
    };

    logoutUser();
  }, [navigate]);

  return null;  // No UI rendering, just handling the logout logic
};

export default Logout;
