import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const FormContainer = styled.div`
  max-width: 400px;
  margin: 20px auto;
  padding: 30px;
  border: 1px solid #e0e0e0; /* Soft border to align with brand */
  border-radius: 10px;
  background-color: #ffffff; /* White background for a clean, modern feel */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
`;

const FormTitle = styled.h2`
  text-align: center;
  font-size: 1.8em;
  color: #0047ba; /* Primary brand color */
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 1em;
  color: #555; /* Dark gray for the text */
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9; /* Light gray background for inputs */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #0047ba; /* Brand color on focus */
    box-shadow: 0 0 10px rgba(0, 71, 186, 0.3); /* Brand color glow */
    background-color: #fff;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background: linear-gradient(135deg, #0047ba, #00d4ff); /* Gradient based on brand colors */
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: linear-gradient(135deg, #00d4ff, #0047ba); /* Hover gradient flip */
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c; /* Error color */
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
`;

const PasswordChangeForm = () => {
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    key: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(''); // Reset error message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.newPassword !== formData.confirmPassword) {
      setError('New passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Authentication token is missing.');
        setLoading(false);
        return;
      }

      const response = await fetch('https://limitincrease.xyz/api/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },
        body: JSON.stringify({
          current_password: formData.currentPassword,
          new_password: formData.newPassword,
          confirm_new_password: formData.confirmPassword,
          key: formData.key,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail
            ? Array.isArray(errorData.detail)
              ? errorData.detail.map((err) => err.msg).join(', ')
              : errorData.detail
            : 'Failed to change password.'
        );
      }

      const data = await response.json();
      alert(data.message || 'Password changed successfully.');
      setFormData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        key: '',
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Change Password</FormTitle>
      <form onSubmit={handleSubmit}>
        <Label>Current Password:</Label>
        <Input
          type="password"
          name="currentPassword"
          value={formData.currentPassword}
          onChange={handleChange}
          required
        />

        <Label>New Password:</Label>
        <Input
          type="password"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          required
        />

        <Label>Confirm New Password:</Label>
        <Input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />

        <Label>Enter Key:</Label>
        <Input
          type="password"
          name="key"
          value={formData.key}
          onChange={handleChange}
          required
        />

        <SubmitButton type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Change Password'}
        </SubmitButton>
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormContainer>
  );
};

export default PasswordChangeForm;
