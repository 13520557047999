import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CarouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      image: "/images/crypto1.jpeg", // local image path
      title: "Invest in Your Future with Crypto",
      description: "Join the world of cryptocurrency and secure your financial future. Start today and watch your assets grow!",
      cta: "Get Started"
    },
    {
      image: "/images/crypto2.jpeg", // local image path
      title: "Decentralized Finance at Your Fingertips",
      description: "Explore a world of financial opportunities with decentralized technology. Earn passive income with ease.",
      cta: "Explore Now"
    },
    {
      image: "/images/crypto3.jpeg", // local image path
      title: "The Future of Investment is Here",
      description: "Get in on the ground floor of the next big thing. Start investing today and be part of the revolution!",
      cta: "Start Investing"
    }
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  return (
    <Carousel>
      <CarouselSlide>
        <CarouselImage src={slides[currentSlide].image} alt="Promo" />
        <CarouselCaption>
          <h1>{slides[currentSlide].title}</h1>
          <p>{slides[currentSlide].description}</p>
          <CtaButton href="/investment">{slides[currentSlide].cta}</CtaButton>
        </CarouselCaption>
      </CarouselSlide>
    </Carousel>
  );
};

export default CarouselComponent;

// Styled Components

const Carousel = styled.div`
  width: 100%;
  height: 100vh; /* Full screen height */
  position: relative;
  overflow: hidden;
  background-color: #000;
`;

const CarouselSlide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the area without distortion */
  transition: opacity 1s ease;
`;

const CarouselCaption = styled.div`
  position: absolute;
  bottom: 20%;
  left: 10%;
  transform: translateY(20px);
  color: white;
  text-align: left;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 10px;
  width: 50%;
`;

CarouselCaption.h1 = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
`;

CarouselCaption.p = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const CtaButton = styled.a`
  display: inline-block;
  padding: 12px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

// Mobile Responsiveness
const MediaQuery = styled.div`
  @media (max-width: 768px) {
    ${CarouselCaption} {
      left: 10%;
      width: 80%;
    }

    ${CarouselCaption.h1} {
      font-size: 28px;
    }

    ${CarouselCaption.p} {
      font-size: 16px;
    }

    ${CtaButton} {
      font-size: 16px;
    }
  }
`;
