import React, { useState } from 'react';
import styled from 'styled-components'; // Import styled-components

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderContainer>
      <Logo>
        <h1>Limit Increase</h1>
      </Logo>
      <Nav className={isMenuOpen ? 'active' : ''}>
        <ul>
          <li><NavLink href="/" onClick={() => setIsMenuOpen(false)}>Home</NavLink></li>
          <li><NavLink href="/wallet" onClick={() => setIsMenuOpen(false)}>Wallet</NavLink></li>
          <li><NavLink href="/investment" onClick={() => setIsMenuOpen(false)}>Investment</NavLink></li>
          <li><NavLink href="/vip" onClick={() => setIsMenuOpen(false)}>VIP</NavLink></li>
          <li><NavLink href="/dashboard" onClick={() => setIsMenuOpen(false)}>Dashboard</NavLink></li>
          <li><NavLink href="/logout" onClick={() => setIsMenuOpen(false)}>Logout</NavLink></li>
        </ul>
      </Nav>
      <Hamburger onClick={toggleMenu} aria-label="Toggle navigation menu" aria-expanded={isMenuOpen ? 'true' : 'false'}>
        <Bar className={`bar1 ${isMenuOpen ? 'open' : ''}`} />
        <Bar className={`bar2 ${isMenuOpen ? 'open' : ''}`} />
        <Bar className={`bar3 ${isMenuOpen ? 'open' : ''}`} />
      </Hamburger>
    </HeaderContainer>
  );
};

export default Header;

// Styled Components

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2C3E50; /* Primary branding color */
  color: white;
  padding: 15px 30px;
  position: relative;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    padding: 15px 20px;
  }
`;

const Logo = styled.div`
  h1 {
    margin: 0;
    font-size: 1.8rem;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;

    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    gap: 25px;
  }

  &.active {
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #2C3E50; /* Match header color */
    width: 220px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    display: none;

    &.active {
      display: block;
    }

    ul {
      flex-direction: column;
      gap: 15px;
    }
  }
`;

const NavLink = styled.a`
  color: #e3f2fd; /* Light blue for contrast */
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #5c6bc0; /* Highlight color */
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
  transition: transform 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const Bar = styled.span`
  width: 30px;
  height: 3px;
  background-color: white;
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: rotate(45deg) translateY(8px);
  }

  &.bar2.open {
    opacity: 0;
  }

  &.bar3.open {
    transform: rotate(-45deg) translateY(-8px);
  }
`;
