import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ProfilePageWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
`;

const ProfileHeader = styled.h2`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #0047ba; /* Primary brand color */
`;

const ProfileDetails = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
`;

const DetailItem = styled.div`
  margin-bottom: 15px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333; /* Dark text color */

  strong {
    font-weight: bold;
    color: #0047ba; /* Primary brand color */
  }
`;

const ReferralSection = styled.div`
  margin-top: 30px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ReferralText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #0047ba; /* Primary brand color */
  text-align: center;
`;

const ReferralInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const ReferralInput = styled.input`
  flex: 1;
  padding: 12px;
  font-size: 16px;
  color: #333;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  width: 100%;
`;

const CopyButton = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: #0047ba; /* Primary brand color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #002f84; /* Darker shade of the primary color */
  }
`;

const CopyFeedback = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #2ecc71; /* Success feedback color */
  text-align: center;
`;

const ProfilePage = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) {
        setError('No token found! Please log in.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://limitincrease.xyz/api/profile', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            setError('Unauthorized! Please log in again.');
          } else {
            setError('Error fetching profile details');
          }
          return;
        }

        const data = await response.json();
        setUserProfile(data);
      } catch (err) {
        setError('Failed to fetch profile. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  const copyReferralLink = () => {
    if (userProfile && userProfile.referral_link) {
      navigator.clipboard.writeText(userProfile.referral_link)
        .then(() => {
          setCopySuccess('Referral link copied to clipboard!');
          setTimeout(() => setCopySuccess(''), 3000); // Clear feedback after 3 seconds
        })
        .catch((err) => {
          console.error('Failed to copy referral link: ', err);
        });
    }
  };

  if (loading) {
    return <ProfilePageWrapper>Loading...</ProfilePageWrapper>;
  }

  if (error) {
    return <ProfilePageWrapper>Error: {error}</ProfilePageWrapper>;
  }

  if (!userProfile) {
    return <ProfilePageWrapper>No profile details available.</ProfilePageWrapper>;
  }

  return (
    <ProfilePageWrapper>
      <ProfileHeader>User Profile</ProfileHeader>
      <ProfileDetails>
        <DetailItem>
          <strong>Username:</strong> {userProfile.username}
        </DetailItem>
        <DetailItem>
          <strong>Email:</strong> {userProfile.email}
        </DetailItem>
        <DetailItem>
          <strong>Phone:</strong> {userProfile.phone}
        </DetailItem>
        <DetailItem>
          <strong>Wallet ID:</strong> {userProfile.wallet_id}
        </DetailItem>
        <DetailItem>
          <strong>VIP Level:</strong> {userProfile.vip_level}
        </DetailItem>
        <DetailItem>
          <strong>Balance:</strong> {userProfile.balance} USDT
        </DetailItem>
      </ProfileDetails>

      <ReferralSection>
        <ReferralText>Referral Link</ReferralText>
        <ReferralInputContainer>
          <ReferralInput
            type="text"
            value={userProfile.referral_link}
            readOnly
          />
          <CopyButton onClick={copyReferralLink}>Copy Link</CopyButton>
        </ReferralInputContainer>
        {copySuccess && <CopyFeedback>{copySuccess}</CopyFeedback>}
      </ReferralSection>
    </ProfilePageWrapper>
  );
};

export default ProfilePage;
