import React from 'react';
import styled from 'styled-components'; // Import styled-components

const FooterMenu = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink href="/help">Help</FooterLink>
        <FooterLink href="/contact">Support</FooterLink>
        <FooterLink href="/terms">Terms of Service</FooterLink>
        <FooterLink href="/privacy">Privacy Policy</FooterLink>
      </FooterLinks>
      <FooterInfo>
        <p>&copy; {new Date().getFullYear()} Crypto SACCO. All rights reserved.</p>
      </FooterInfo>
    </FooterContainer>
  );
};

export default FooterMenu;

// Styled Components

const FooterContainer = styled.footer`
  background-color: #2C3E50; /* Primary branding color */
  color: white;
  padding: 30px 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
`;

const FooterLinks = styled.div`
  margin-bottom: 15px;
`;

const FooterLink = styled.a`
  color: #bbdefb; /* Light blue for contrast */
  margin: 0 15px;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: #ffffff; /* White on hover for better visibility */
  }

  @media (max-width: 768px) {
    margin: 0 10px;
    font-size: 13px;
  }
`;

const FooterInfo = styled.div`
  font-size: 12px;
  color: #e3f2fd; /* Slightly lighter tone for text */
  margin-top: 10px;
`;
