// src/components/Transactions.js
import React, { useEffect, useState } from 'react';
import { FaSort } from 'react-icons/fa';
import styled from 'styled-components';

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('amount'); // Default sort by amount
  const [categories, setCategories] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const rowsPerPage = 3;

  const fetchTransactions = async () => {
    try {
      const response = await fetch('https://limitincrease.xyz/api/transactions', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch transactions.');
      }

      const data = await response.json();
      setTransactions(data);

      // Categorize transactions by type
      const grouped = data.reduce((acc, transaction) => {
        acc[transaction.transaction_type] = acc[transaction.transaction_type] || [];
        acc[transaction.transaction_type].push(transaction);
        return acc;
      }, {});
      setCategories(grouped);

      // Initialize current page for each category
      const initialPageState = Object.keys(grouped).reduce((acc, category) => {
        acc[category] = 1; // Start on the first page
        return acc;
      }, {});
      setCurrentPage(initialPageState);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const sortTransactions = (transactions) => {
    return transactions.sort((a, b) => {
      if (sortBy === 'amount') return b.amount - a.amount;
      if (sortBy === 'date') return new Date(b.timestamp) - new Date(a.timestamp);
      return 0;
    });
  };

  const paginate = (transactions, page) => {
    const startIndex = (page - 1) * rowsPerPage;
    return transactions.slice(startIndex, startIndex + rowsPerPage);
  };

  const handlePageChange = (category, direction) => {
    setCurrentPage((prevState) => ({
      ...prevState,
      [category]: prevState[category] + direction,
    }));
  };

  return (
    <TransactionHistoryContainer>
      <h2>Transaction History</h2>

      <SortContainer>
        <SortOptions>
          <FaSort className="sort-icon" />
          <span className="sort-label">Sort by:</span>
          <SortButton
            className={sortBy === 'amount' ? 'active' : ''}
            onClick={() => setSortBy('amount')}
          >
            Amount
          </SortButton>
          <SortButton
            className={sortBy === 'date' ? 'active' : ''}
            onClick={() => setSortBy('date')}
          >
            Date
          </SortButton>
        </SortOptions>
      </SortContainer>

      <GridContainer>
        {Object.keys(categories).length > 0 ? (
          Object.keys(categories).map((category) => {
            const categoryTransactions = sortTransactions(categories[category]);
            const paginatedTransactions = paginate(categoryTransactions, currentPage[category] || 1);

            return (
              <TransactionCategory key={category}>
                <CategoryTitle>{category}</CategoryTitle>
                <TableContainer>
                  <TransactionTable>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Amount (USDT)</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedTransactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td>{transaction.status}</td>
                          <td>${transaction.amount.toFixed(2)}</td>
                          <td>{new Date(transaction.timestamp).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </TransactionTable>
                </TableContainer>

                <PaginationControls>
                  <PaginationButton
                    onClick={() => handlePageChange(category, -1)}
                    disabled={currentPage[category] <= 1}
                  >
                    Previous
                  </PaginationButton>
                  <PaginationInfo>
                    Page {currentPage[category] || 1} of {Math.ceil(categoryTransactions.length / rowsPerPage)}
                  </PaginationInfo>
                  <PaginationButton
                    onClick={() => handlePageChange(category, 1)}
                    disabled={(currentPage[category] || 1) >= Math.ceil(categoryTransactions.length / rowsPerPage)}
                  >
                    Next
                  </PaginationButton>
                </PaginationControls>
              </TransactionCategory>
            );
          })
        ) : (
          <p>No transactions found.</p>
        )}
      </GridContainer>
    </TransactionHistoryContainer>
  );
};


// Styled Components

const TransactionHistoryContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  h2 {
    font-size: 1.8em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const SortContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const SortOptions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SortButton = styled.button`
  padding: 8px 15px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    background-color: #0056b3;
  }

  &:hover {
    background-color: #0056b3;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
`;

const TransactionCategory = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

const CategoryTitle = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
  margin-bottom: 15px;
`;

const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
`;

const TransactionTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    word-wrap: break-word;
  }

  th {
    background-color: #f1f1f1;
    font-weight: bold;
    color: #555;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  font-size: 0.9em;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PaginationInfo = styled.span`
  font-size: 0.9em;
  color: #555;
`;

export default Transactions;
