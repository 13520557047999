import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Function to mask email
const maskEmail = (email) => {
  const emailParts = email.split('@');
  const localPart = emailParts[0];
  const domainPart = emailParts[1];

  // Mask part of the local part of the email, keeping the first 3 characters and hiding the rest
  const maskedLocalPart = localPart.slice(0, 3) + '*****';
  return `${maskedLocalPart}@${domainPart}`;
};

// Generate random sample data (300 entries)
const generateRandomData = () => {
  const actions = ['withdrew', 'transferred', 'recharged'];
  const emails = [
    'jane.doe@gmail.com', 'mike.smith@hotmail.com', 'emily.jameson@yahoo.com', 'robert.brown@outlook.com',
    'olivia.jones@gmail.com', 'richard.white@hotmail.com', 'susan.miller@icloud.com', 'william.wilson@gmail.com',
    'lucy.moore@aol.com', 'charlie.davis@gmail.com', 'alice.martin@hotmail.com', 'david.clark@outlook.com',
    'isabella.martin@icloud.com', 'lucas.dixon@gmail.com', 'kate.anderson@hotmail.com', 'eva.thompson@aol.com',
    'lucas.dixon@gmail.com', 'grace.hughes@aol.com', 'daniel.fisher@gmail.com', 'mia.green@gmail.com'
  ];

  const randomData = [];
  for (let i = 0; i < 300; i++) {
    const email = emails[Math.floor(Math.random() * emails.length)];
    const action = actions[Math.floor(Math.random() * actions.length)];
    const amount = `${Math.floor(Math.random() * 1000) + 100}USDT`; // Random amount between 100 and 1000
    const time = `${Math.floor(Math.random() * 60)} minutes ago`;

    randomData.push({
      email,
      action,
      amount,
      time
    });
  }

  return randomData;
};

const actions = generateRandomData();

const TextCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500, // Control speed of rotation
    slidesToShow: 5, // Show 5 items at once for a circular look on larger screens
    centerMode: true, // Enable center mode for dominant center item
    centerPadding: '0', // No padding around the center
    autoplay: true,
    autoplaySpeed: 1000, // Adjust speed between slides
    focusOnSelect: true, // Enable selecting the active slide
    responsive: [
      {
        breakpoint: 768, // Apply this for mobile screens (below 768px)
        settings: {
          slidesToShow: 1, // Show 1 item at a time on mobile
          centerMode: false, // Disable center mode for mobile
        },
      },
    ],
  };

  return (
    <CarouselSection>
      <Slider {...settings}>
        {actions.map((action, index) => (
          <CarouselItem key={index}>
            <p className="transaction-text">
              <strong>{maskEmail(action.email)}</strong> {action.action} {action.amount} <span className="time">{action.time}</span>
            </p>
          </CarouselItem>
        ))}
      </Slider>
    </CarouselSection>
  );
};

export default TextCarousel;

// Styled Components

const CarouselSection = styled.section`
  text-align: center;
  margin: 60px 0;
  padding: 30px;
  background-color: #f9f9f9;
  font-family: 'Inter', sans-serif;

  h2 {
    font-size: 36px;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
    font-family: 'Lora', serif;
  }
`;

const CarouselItem = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  
  .transaction-text {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    letter-spacing: 0.5px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .time {
    font-size: 14px;
    color: #888;
    margin-top: 5px;
  }

  /* Make the center item larger */
  &.slick-center {
    transform: scale(1.2); /* Center item will be larger */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Scale down other items slightly */
  &:not(.slick-center) {
    transform: scale(0.8);
    opacity: 0.6;
  }
`;
