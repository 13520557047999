import React, { useState } from 'react';
import styled from 'styled-components';

const HelpCenter = () => {
  const faqs = [
    {
      question: "What is a VIP level?",
      answer: "VIP levels determine your transaction limits and access to certain features. Higher levels offer more benefits, such as increased withdrawal limits, exclusive promotions, and priority support. Level up by completing more transactions.",
    },
    {
      question: "How can I contact support?",
      answer: "You can contact support by visiting the 'Contact Support' page or sending an email to support@example.com. Our support team is available 24/7 and will assist you with any inquiries or issues.",
    },
    {
      question: "How do I make a withdrawal?",
      answer: "To make a withdrawal, go to the 'Withdraw' page, enter the amount, and follow the on-screen instructions. Ensure your bank account details are up-to-date for seamless processing.",
    },
    {
      question: "Is my data secure?",
      answer: "Yes, your data is highly secure. We use industry-standard encryption protocols to ensure your personal and financial information is protected at all times. Privacy is our top priority.",
    },
    {
      question: "Can I access the Help Center on mobile?",
      answer: "Absolutely! Our Help Center is fully optimized for mobile devices. You can access all FAQs and contact support from your phone or tablet seamlessly.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Container>
      <Title>Help Center</Title>
      <AccordionContainer>
        {faqs.map((faq, index) => (
          <AccordionItem key={index}>
            <AccordionHeader onClick={() => toggleAccordion(index)}>
              <Question>{faq.question}</Question>
              <ToggleButton className={activeIndex === index ? 'active' : ''}>
                {activeIndex === index ? '-' : '+'}
              </ToggleButton>
            </AccordionHeader>
            {activeIndex === index && (
              <AccordionContent>
                <Answer>{faq.answer}</Answer>
              </AccordionContent>
            )}
          </AccordionItem>
        ))}
      </AccordionContainer>
    </Container>
  );
};

export default HelpCenter;

// Styled Components

const Container = styled.div`
  padding: 40px;
  background-color: #f8fafc;
  max-width: 900px;
  margin: 40px auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #0d47a1;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const AccordionContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f1f3f5;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e3f2fd;
  }

  @media (max-width: 768px) {
    padding: 12px 15px;
  }
`;

const Question = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ToggleButton = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #0d47a1;
  transition: color 0.3s ease;

  &.active {
    color: #d32f2f; /* Active toggle color */
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const AccordionContent = styled.div`
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    padding: 12px 15px;
  }
`;

const Answer = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin: 0;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
