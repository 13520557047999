import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import styled from 'styled-components';

// Styled Components
const RechargePage = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #2d3436;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  color: #2d3436;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const Description = styled.p`
  text-align: center;
  font-size: 18px;
  color: #636e72;
  margin-bottom: 30px;
`;

const RechargeForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  border: 1px solid #e6e6e6;
  padding: 30px;
  border-radius: 12px;
  background-color: #f9f9f9;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #34495e;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  color: #2d3436;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.3);
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  color: #2d3436;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.3);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 14px 24px;
  background-color: #3498db;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DepositInfo = styled.div`
  margin-top: 40px;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
`;

const DepositLink = styled.p`
  font-size: 18px;
  color: #2d3436;
  font-weight: 500;
  margin-bottom: 15px;
  word-wrap: break-word;
`;

const CopyButton = styled(Button)`
  background-color: #3498db;

  &:hover {
    background-color: #2980b9;
  }
`;

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  canvas {
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  }
`;

const Note = styled.p`
  font-size: 14px;
  color: #7f8c8d;
  margin-top: 10px;
  text-align: center;
`;

// Component Logic
const Recharge = () => {
  const [formData, setFormData] = useState({ amount: '', currency: 'USDT_TRC20', depositLink: '' });

  const walletAddresses = {
    USDT_ERC20: "THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH",
    USDT_TRC20:"0x998f046a38402fa0c95d8fbbcb2ee51975b9d019",
    TRX: "TL5Fx3pSH5mDSVV65WCPBGgepLVvuYGhjc",
    BTC: "bc1q49xc76ktk3fea4jfaxxkyhxw0lf7s9v2ctfjc2",
    ETH: "0x44454A89e9A4f2DA93EfC1b27A3dECc128A09aed",
    SOL: "0x44454A89e9A4f2DA93EfC1b27A3dECc128A09aed",
    BNB: "bnb1q8clmmd46j2jvn8adw79g0jg3z0rhuyl6nkc8y",
    XRP: "r33GDVU973s8AyGdGiwaQ3Da8iiHa2Z7yd",
    DOGE: "DPjb2Gz47Bd5qBZLcDkNPdY5DSwk4i1Q5N",
    ADA: "addr1qxmz9xpa93rk3hrfgsvaq0khmt7czjx3zekmeeea4rxy29aky2vr6tz8drwxj3qe6qld0khas9ydz9ndhnnnm2xvg5tswsr4cp",
    USDC: "0x44454A89e9A4f2DA93EfC1b27A3dECc128A09aed",
  };

  const [address, setAddress] = useState(walletAddresses['TRX']);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value < 0) {
      alert("Amount cannot be negative");
      setFormData({ ...formData, amount: '' });
      return;
    }
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleCurrencyChange = (e) => {
    const selectedCurrency = e.target.value;
    setFormData({ ...formData, currency: selectedCurrency });
    setAddress(walletAddresses[selectedCurrency]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const depositAmount = formData.amount;
    if (depositAmount <= 0) {
      alert("Please enter a valid amount.");
      return;
    }
    const link = `${address}`;
    setFormData({ ...formData, depositLink: link });
  };

  const handleCopyAddressAmount = () => {
    navigator.clipboard.writeText(address)
      .then(() => alert('Address copied to clipboard!'))
      .catch((err) => console.error('Failed to copy: ', err));
  };

  return (
    <RechargePage>
      <Title>Recharge Your Account</Title>
      <Description>
        Use the secure recharge form to deposit funds into your account. Select your preferred cryptocurrency and provide the desired amount. Your unique deposit address and QR code will be displayed after submission.
      </Description>
      <RechargeForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Select Currency:</Label>
          <Select name="currency" value={formData.currency} onChange={handleCurrencyChange}>
            {Object.keys(walletAddresses).map((currency) => (
              <option key={currency} value={currency}>{currency}</option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Enter Amount:</Label>
          <Input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            required
            min="0"
          />
        </FormGroup>
        <Button type="submit">Generate Deposit Address</Button>
      </RechargeForm>

      {formData.depositLink && (
        <DepositInfo>
          <h3>Deposit Address</h3>
          <DepositLink>{formData.depositLink}</DepositLink>
          <CopyButton onClick={handleCopyAddressAmount}>Copy Address</CopyButton>
          <QRCodeContainer>
            <QRCodeCanvas value={formData.depositLink} size={200} />
          </QRCodeContainer>
          <Note>Please ensure that the amount matches the network and currency selected. Incorrect deposits may result in lost funds.</Note>
        </DepositInfo>
      )}
    </RechargePage>
  );
};

export default Recharge;
