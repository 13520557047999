import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { FaRegUser, FaWallet, FaCopy } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import styled from 'styled-components';

const WalletContainer = styled.div`
  max-width: 480px;
  margin: 20px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const WalletHeader = styled.div`
  text-align: center;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #444;
  }
`;

const WalletDetails = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 12px;
  margin-bottom: 30px;
`;

const WalletInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  p {
    margin: 0;
    margin-left: 12px;
    font-size: 1rem;
    color: #333;
  }

  strong {
    font-weight: bold;
    color: #2d3436;
  }
`;

const WalletIcon = styled.div`
  font-size: 24px;
  color: #3498db; /* Brand blue color */
`;

const CopyButton = styled.button`
  margin-top: 10px;
  padding: 10px 18px;
  font-size: 1rem;
  background-color: #28a745; /* Brand green color */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const QRCodeContainer = styled.div`
  text-align: center;
  margin-top: 30px;

  h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.4rem;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #3498db;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #e74c3c;
`;

const Wallet = () => {
  const [walletDetails, setWalletDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchWalletDetails = async () => {
      if (!token) {
        setError('No token found! Please log in.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://limitincrease.xyz/api/wallet', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching wallet details');
        }

        const data = await response.json();
        setWalletDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWalletDetails();
  }, [token]);

  const handleCopy = () => {
    navigator.clipboard.writeText(walletDetails.wallet_id)
      .then(() => alert("Wallet ID copied to clipboard!"))
      .catch((err) => console.error("Failed to copy Wallet ID", err));
  };

  if (loading) {
    return <LoadingMessage>Loading...</LoadingMessage>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!walletDetails) {
    return <LoadingMessage>No wallet details available.</LoadingMessage>;
  }

  return (
    <WalletContainer>
      <WalletHeader>
        <h1>Wallet Details</h1>
      </WalletHeader>

      <WalletDetails>
        <WalletInfo>
          <WalletIcon>
            <FaRegUser />
          </WalletIcon>
          <p>
            <strong>Name:</strong> {walletDetails.username}
          </p>
        </WalletInfo>

        <WalletInfo>
          <WalletIcon>
            <AiOutlineMail />
          </WalletIcon>
          <p>
            <strong>Email:</strong> {walletDetails.email}
          </p>
        </WalletInfo>

        <WalletInfo>
          <WalletIcon>
            <FaWallet />
          </WalletIcon>
          <p>
            <strong>Balance:</strong> {walletDetails.balance} USDT
          </p>
        </WalletInfo>

        <WalletInfo>
          <WalletIcon>
            <FaWallet />
          </WalletIcon>
          <p>
            <strong>VIP Level:</strong> {walletDetails.vip_level}
          </p>
        </WalletInfo>

        <WalletInfo>
          <p>
            <strong>Wallet ID:</strong> {walletDetails.wallet_id}
          </p>
          <CopyButton onClick={handleCopy}>
            <FaCopy /> Copy Wallet ID
          </CopyButton>
        </WalletInfo>
      </WalletDetails>

      <QRCodeContainer>
        <h2>Scan Your Wallet</h2>
        <QRCodeCanvas value={walletDetails.wallet_id} size={256} />
      </QRCodeContainer>
    </WalletContainer>
  );
};

export default Wallet;
