import React from 'react';
import styled from 'styled-components';
import { FaDollarSign, FaWallet, FaMedal } from 'react-icons/fa';

// Styled Components
const ServicesSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 30px;
  background-color: #f0f4f8; /* Lighter background color to match brand */
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
`;

const Service = styled.div`
  text-align: center;
  max-width: 320px;
  flex: 1;
  margin: 20px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
`;

const ServiceIcon = styled.div`
  background-color: #3498db; /* Brand color for icon background */
  padding: 20px;
  border-radius: 50%;
  color: white;
  margin-bottom: 15px;
  display: inline-block;
`;

const ServiceHeading = styled.h3`
  font-size: 24px;
  color: #2d3436; /* Darker text color for better readability */
  margin-bottom: 10px;
  font-weight: bold;
`;

const ServiceDescription = styled.p`
  color: #555;
  font-size: 16px;
  margin-bottom: 20px;
`;

const CTAButton = styled.a`
  padding: 12px 24px;
  font-size: 16px;
  color: #ffffff;
  background-color: #3498db; /* Matching button color with icon */
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;

  &:hover {
    background-color: #2980b9; /* Slightly darker on hover */
    transform: scale(1.05);
  }

  &:active {
    background-color: #1d68a7; /* Darker on active */
  }
`;

const ServiceOverview = () => {
  return (
    <ServicesSection>
      <Service>
        <ServiceIcon>
          <FaDollarSign size={50} />
        </ServiceIcon>
        <ServiceHeading>Invest with Us</ServiceHeading>
        <ServiceDescription>
          Choose from multiple high-yield investment packages tailored for your financial goals. Maximize returns with minimal risk!
        </ServiceDescription>
        <CTAButton href="/investment">Get Started</CTAButton>
      </Service>
      <Service>
        <ServiceIcon>
          <FaWallet size={50} />
        </ServiceIcon>
        <ServiceHeading>Manage Your Wallet</ServiceHeading>
        <ServiceDescription>
          Your wallet is automatically set up and secured. Effortlessly manage your funds, track transactions, and make secure transfers with full control over your assets.
        </ServiceDescription>
        <CTAButton href="/wallet">Access Wallet</CTAButton>
      </Service>
      <Service>
        <ServiceIcon>
          <FaMedal size={50} />
        </ServiceIcon>
        <ServiceHeading>Upgrade to VIP</ServiceHeading>
        <ServiceDescription>
          Unlock premium features, exclusive benefits, and higher transaction limits. Elevate your experience with VIP status.
        </ServiceDescription>
        <CTAButton href="/vip">Join VIP</CTAButton>
      </Service>
    </ServicesSection>
  );
};

export default ServiceOverview;
