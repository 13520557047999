import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const vipLevels = [
  { level_name: "Non-VIP", transfer_limit_daily: 0, transfer_limit_monthly: 0 },
  { level_name: "Starter", transfer_limit_daily: 200, transfer_limit_monthly: 2500 },
  { level_name: "Basic", transfer_limit_daily: 900, transfer_limit_monthly: 9500 },
  { level_name: "Pro", transfer_limit_daily: 1500, transfer_limit_monthly: 18000 },
  { level_name: "Premium", transfer_limit_daily: 10000, transfer_limit_monthly: 25000 },
  { level_name: "Platinum", transfer_limit_daily: 30000, transfer_limit_monthly: 8000000 },
];

const TransferForm = () => {
  const [formData, setFormData] = useState({
    recipientEmail: '',
    amount: '',
    senderWalletId: '',
  });
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch('https://limitincrease.xyz/api/user', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setFormData((prevData) => ({ ...prevData, senderWalletId: userData.wallet_id }));
        } else {
          setMessage('Could not fetch user details. Please log in again.');
        }
      } catch (error) {
        setMessage('An error occurred while fetching user details. Please try again.');
      }
    };

    fetchUserDetails();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parseFloat(formData.amount) <= 0) {
      setMessage('Amount must be a positive number.');
      return;
    }

    const fetchRecipientWalletId = async (email) => {
      try {
        const response = await fetch(`https://limitincrease.xyz/api/user?email=${email}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const recipientData = await response.json();
          return recipientData.wallet_id;
        } else {
          setMessage('Recipient not found. Please check the email address.');
          return null;
        }
      } catch (error) {
        setMessage('An error occurred while fetching recipient details. Please try again.');
        return null;
      }
    };

    const recipientWalletId = await fetchRecipientWalletId(formData.recipientEmail);
    if (!recipientWalletId) return;

    const requestBody = {
      recipient_email: formData.recipientEmail,
      amount: parseFloat(formData.amount),
      sender_wallet_id: formData.senderWalletId,
      recipient_wallet_id: recipientWalletId,
    };

    try {
      const response = await fetch('https://limitincrease.xyz/api/transfer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(`${formData.amount} sent to ${formData.recipientEmail} successfully.`);
        setSuccess(true);
        setFormData({ recipientEmail: '', amount: '', senderWalletId: formData.senderWalletId });
      } else {
        setMessage(data.detail || 'An unexpected error occurred.');
        setSuccess(false);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setSuccess(false);
    }
  };

  return (
    <FormContainer>
      <h2>Transfer Funds</h2>

      <InfoBanner>
        <p>
          <strong>Note:</strong> You can only transfer funds to someone registered on this platform. 
          Both the sender and recipient must be VIP members. No key is required for transfers.
        </p>
      </InfoBanner>

      <TransferLimits>
        <h3>VIP Transfer Limits</h3>
        <ul>
          {vipLevels.map((level) => (
            <li key={level.level_name}>
              <strong>{level.level_name}:</strong> Daily: ${level.transfer_limit_daily.toLocaleString()} | Monthly: ${level.transfer_limit_monthly.toLocaleString()}
            </li>
          ))}
        </ul>
      </TransferLimits>

      <form onSubmit={handleSubmit}>
        <FormField>
          <label>Recipient Email:</label>
          <Input
            type="email"
            name="recipientEmail"
            value={formData.recipientEmail}
            onChange={handleChange}
            required
          />
        </FormField>

        <FormField>
          <label>Amount:</label>
          <Input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            required
            min="0"
          />
        </FormField>

        <Button type="submit">Transfer</Button>
      </form>

      {message && <Message success={success}>{message}</Message>}

      <BackLink>
        Back to <Link to="/dashboard">Dashboard</Link>
      </BackLink>
    </FormContainer>
  );
};

export default TransferForm;

// Styled Components

const FormContainer = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
`;

const InfoBanner = styled.div`
  background-color: #eaf4fe;
  color: #0056b3;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 16px;
`;

const TransferLimits = styled.div`
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 8px;
      font-size: 16px;
      color: #555;
    }
  }
`;

const FormField = styled.div`
  margin-bottom: 20px;

  label {
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
    color: #333;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px;
`;

const Message = styled.p`
  text-align: center;
  font-size: 16px;
`;

const BackLink = styled.div`
  text-align: center;
`;
