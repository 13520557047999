import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-width: 420px;
  width: 100%;
  border: 1px solid #e0e0e0;
`;

const Heading = styled.h2`
  text-align: center;
  color: #0047ba; /* Primary brand color */
  font-size: 2em;
  margin-bottom: 30px;
  font-weight: 600;
`;

const Label = styled.label`
  display: block;
  font-size: 1.1em;
  color: #333;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  color: #333;
  background-color: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #0047ba; /* Primary brand color */
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 71, 186, 0.5); /* Subtle glow effect */
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 14px 16px;
  background-color: #0047ba; /* Primary brand color */
  color: white;
  font-size: 1.1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #003a94; /* Darker shade on hover */
  }

  &:active {
    background-color: #003078; /* Even darker shade when active */
  }
`;

const Message = styled.p`
  text-align: center;
  color: #e74c3c; /* Red color for error messages */
  font-size: 1.1em;
  margin-top: 10px;
`;

const ExtraLinks = styled.div`
  text-align: center;
  margin-top: 30px;
  font-size: 1.1em;
  color: #555;
`;

const StyledLink = styled(Link)`
  color: #0047ba; /* Primary brand color */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      email: formData.email,
      password: formData.password,
    };

    try {
      const response = await fetch('https://limitincrease.xyz/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        console.log('Login successful:', data);

        // Store the access token in localStorage
        localStorage.setItem('token', data.access_token);

        // Redirect to home page after successful login
        navigate('/');
      } else {
        const errorData = await response.json();
        setMessage(errorData.detail);
        console.error('Error:', errorData);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Container>
      <FormContainer>
        <Heading>Login</Heading>
        <form onSubmit={handleSubmit}>
          <div>
            <Label>Email:</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label>Password:</Label>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <SubmitButton type="submit">Login</SubmitButton>
        </form>

        {message && <Message>{message}</Message>}

        <ExtraLinks>
          <p>
            Forgot your password? <StyledLink to="/request-password-reset">Reset here</StyledLink>
          </p>
          <p>
            Don't have an account? <StyledLink to="/register">Register here</StyledLink>
          </p>
        </ExtraLinks>
      </FormContainer>
    </Container>
  );
};

export default LoginForm;
