import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0047ba, #002f84);
  padding: 20px;
`;

const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  width: 100%;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }
`;

const Heading = styled.h2`
  text-align: center;
  color: #0047ba;
  font-size: 2.2em;
  margin-bottom: 20px;
  font-weight: 700;
`;

const Label = styled.label`
  display: block;
  font-size: 1.1em;
  color: #555;
  margin-bottom: 8px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  color: #333;
  background-color: #f7f9fc;
  transition: all 0.3s ease;

  &:focus {
    border-color: #0047ba;
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 71, 186, 0.5);
  }
`;

const PhoneInputWrapper = styled.div`
  margin-bottom: 20px;
  
  .react-tel-input {
    width: 100%;
    .form-control {
      width: 100%;
      padding: 12px 15px;
      border-radius: 8px;
      border: 1px solid #ddd;
      font-size: 1em;
      color: #333;
      background-color: #f7f9fc;
      transition: all 0.3s ease;

      &:focus {
        border-color: #0047ba;
        background-color: #fff;
        outline: none;
        box-shadow: 0 0 8px rgba(0, 71, 186, 0.5);
      }
    }

    .react-tel-input .country-select {
      background-color: #f7f9fc;
    }

    .react-tel-input .country-list {
      z-index: 10;
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px 15px;
  background-color: #0047ba;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #002f84;
  }

  &:active {
    background-color: #001c4d;
  }
`;

const Message = styled.p`
  text-align: center;
  color: ${(props) => (props.success ? '#27ae60' : '#e74c3c')};
  font-size: 1.1em;
  margin-top: 20px;
`;

const LoginLink = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 1.1em;
  color: #555;
`;

const StyledLink = styled(Link)`
  color: #0047ba;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #0047ba;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '', // Will hold the formatted phone number
    password: '',
    confirmPassword: '',
    referred_by: '',
  });

  const [message, setMessage] = useState('');
  const [key, setKey] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    };

    const referred_by = getCookie('referred_by');
    if (referred_by) {
      setFormData((prevData) => ({ ...prevData, referred_by: referred_by }));
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestBody = {
      username: formData.username,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      confirm_password: formData.confirmPassword,
      referred_by: formData.referred_by,
    };

    try {
      const response = await fetch('https://limitincrease.xyz/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        setKey(data.key); // Set the key from the response
      } else {
        const errorData = await response.json();
        setMessage(errorData.detail);
      }
    } catch (error) {
      setLoading(false);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Container>
      <FormContainer>
        <Heading>Register</Heading>
        <form onSubmit={handleSubmit}>
          <div>
            <Label>Username:</Label>
            <Input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label>Email:</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label>Phone:</Label>
            <PhoneInputWrapper>
              <PhoneInput
                country={'us'}
                value={formData.phone}
                onChange={handlePhoneChange}
              />
            </PhoneInputWrapper>
          </div>
          <div>
            <Label>Password:</Label>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <Label>Confirm Password:</Label>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          <SubmitButton type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : 'Register'}
          </SubmitButton>
        </form>

        {message && <Message success={message.includes('success')}>{message}</Message>}
        
        {key && (
          <Message success>
            Your key: <strong>{key}</strong>. Please copy it, as it will only be shown once.
          </Message>
        )}

        <LoginLink>
          Already have an account? <StyledLink to="/login">Login here</StyledLink>
        </LoginLink>
      </FormContainer>
    </Container>
  );
};

export default RegisterForm;
