import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import styled from "styled-components";

const Investment = () => {
  const [packages, setPackages] = useState([]);
  const [durations, setDurations] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("USDT_TRC20");
  const [duration, setDuration] = useState("");
  const [depositDetails, setDepositDetails] = useState({ link: "", amount: 0, duration: "" });
  const [loading, setLoading] = useState(false);

  const depositLinks = {
    USDT_ERC20: "THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH",
    USDT_TRC20:"0x998f046a38402fa0c95d8fbbcb2ee51975b9d019",
    TRX: "TL5Fx3pSH5mDSVV65WCPBGgepLVvuYGhjc",
    BTC: "bc1q49xc76ktk3fea4jfaxxkyhxw0lf7s9v2ctfjc2",
    ETH: "0x44454A89e9A4f2DA93EfC1b27A3dECc128A09aed",
    SOL: "0x44454A89e9A4f2DA93EfC1b27A3dECc128A09aed",
    BNB: "bnb1q8clmmd46j2jvn8adw79g0jg3z0rhuyl6nkc8y",
    XRP: "r33GDVU973s8AyGdGiwaQ3Da8iiHa2Z7yd",
    DOGE: "DPjb2Gz47Bd5qBZLcDkNPdY5DSwk4i1Q5N",
    ADA: "addr1qxmz9xpa93rk3hrfgsvaq0khmt7czjx3zekmeeea4rxy29aky2vr6tz8drwxj3qe6qld0khas9ydz9ndhnnnm2xvg5tswsr4cp",
    USDC: "0x44454A89e9A4f2DA93EfC1b27A3dECc128A09aed",
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setLoading(true);
        const response = await fetch("https://limitincrease.xyz/investment-packages");
        const data = await response.json();
        setPackages(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to load packages:", error);
        alert("Could not load investment packages. Please try again later.");
        setLoading(false);
      }
    };

    const fetchDurations = async () => {
      try {
        setLoading(true);
        const response = await fetch("https://limitincrease.xyz/investment-durations");
        const data = await response.json();
        setDurations(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to load durations:", error);
        alert("Could not load investment durations. Please try again later.");
        setLoading(false);
      }
    };

    fetchPackages();
    fetchDurations();
  }, []);

  const getToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Please log in to proceed.");
      throw new Error("Unauthorized: No token found");
    }
    return token;
  };

  const handlePackageChange = (e) => {
    const selected = packages.find((pkg) => pkg.name === e.target.value);
    setSelectedPackage(selected ? selected.name : "");
    setAmount(selected ? selected.amount : 0);
  };

  const handleCurrencyChange = (e) => setCurrency(e.target.value);

  const handleDurationChange = (e) => setDuration(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!depositLinks[currency]) {
      alert("No deposit link found for the selected currency.");
      return;
    }

    const finalDepositLink = `${depositLinks[currency]}`;
    setDepositDetails({
      link: finalDepositLink,
      amount: amount,
      duration: duration,
    });

    try {
      const token = getToken();

      const response = await fetch("https://limitincrease.xyz/api/invest", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: parseFloat(amount),
          duration: parseInt(duration, 10),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error details:', errorData);
        throw new Error(errorData.detail || 'Unknown error');
      }

      const data = await response.json();
      console.log('Investment created:', data);

    } catch (error) {
      console.error('Error logging investment:', error.message);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Deposit link copied to clipboard!");
  };

  const downloadQRCode = () => {
    const qrCanvas = document.getElementById("qrCodeCanvas");
    const canvasURL = qrCanvas.toDataURL("image/png");
    const a = document.createElement("a");
    a.href = canvasURL;
    a.download = "deposit-qr-code.png";
    a.click();
  };

  return (
    <InvestmentContainer>
      <Heading>Investment Form</Heading>
      {loading && <LoadingSpinner>Loading...</LoadingSpinner>}

      <InvestmentForm onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="package">Choose an Investment Package:</label>
          <select
            id="package"
            value={selectedPackage}
            onChange={handlePackageChange}
            className="form-select"
          >
            <option value="">Select a package</option>
            {packages.map((pkg, index) => (
              <option key={index} value={pkg.name}>
                {pkg.name}
              </option>
            ))}
          </select>
        </FormGroup>

        {selectedPackage && (
          <>
            <FormGroup>
              <label htmlFor="currency">Choose Currency:</label>
              <select
                id="currency"
                value={currency}
                onChange={handleCurrencyChange}
                className="form-select"
              >
                {Object.keys(depositLinks).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </FormGroup>

            {currency && (
              <>
                <FormGroup>
                  <label htmlFor="duration">Select Duration:</label>
                  <select
                    id="duration"
                    value={duration}
                    onChange={handleDurationChange}
                    className="form-select"
                  >
                    <option value="">Select duration</option>
                    {durations.map((days, index) => (
                      <option key={index} value={days}>
                        {days} days
                      </option>
                    ))}
                  </select>
                </FormGroup>

                {duration && (
                  <InvestmentDetails>
                    <p>
                      <strong>Investment Amount:</strong> {amount} {currency}
                    </p>
                    <p>
                      <strong>Duration:</strong> {duration} days
                    </p>
                  </InvestmentDetails>
                )}
              </>
            )}
          </>
        )}

        <SubmitButton
          type="submit"
          disabled={!selectedPackage || !currency || !duration}
        >
          Proceed
        </SubmitButton>
      </InvestmentForm>

      {depositDetails.link && (
        <DepositDetails>
          <h3>Deposit Details</h3>
          <p>
            <strong>Deposit Link:</strong> {depositDetails.link}
          </p>
          <p>
            <strong>Amount:</strong> {depositDetails.amount} {currency}
          </p>
          <p>
            <strong>Duration:</strong> {depositDetails.duration} days
          </p>

          <QRCodeContainer>
            <QRCodeCanvas id="qrCodeCanvas" value={depositDetails.link} />
          </QRCodeContainer>

          <ButtonGroup>
            <ActionButton onClick={() => copyToClipboard(depositDetails.link)}>
              Copy Link
            </ActionButton>
            <ActionButton onClick={downloadQRCode}>
              Download QR Code
            </ActionButton>
          </ButtonGroup>
        </DepositDetails>
      )}
    </InvestmentContainer>
  );
};

export default Investment;

// Styled Components

const InvestmentContainer = styled.div`
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const LoadingSpinner = styled.div`
  font-size: 1.2rem;
  color: #2575fc;
`;

const InvestmentForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;

  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  select {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
`;

const SubmitButton = styled.button`
  background-color: #2575fc;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 20px;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const DepositDetails = styled.div`
  margin-top: 30px;
`;

const InvestmentDetails = styled.div`
  margin-top: 20px;
`;

const QRCodeContainer = styled.div`
  margin: 20px 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #555;
  }
`;
